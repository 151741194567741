// app.js

import '@aws-amplify/ui-react/styles.css';
import React from 'react';
//import { useState } from 'react';
//import { useEffect } from 'react';
//import Amplify from 'aws-amplify';
//import awsconfig from './aws-exports.js';
import { Button } from '@aws-amplify/ui-react';
import { View } from '@aws-amplify/ui-react';
import { Card } from '@aws-amplify/ui-react';
import { signInWithRedirect } from 'aws-amplify/auth';
//import {
//  fetchAuthSession,
//  getCurrentUser
//} from 'aws-amplify/auth';

const handleSocialSignIn = () => {
  signInWithRedirect({provider: {custom: 'amebusappFederate'}});
}

//awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
//awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;
//Amplify.configure(awsconfig);

function App() {
  // Amazon Federate Midway athentication
//  const [user, setUser] = useState(null);
  // getUser function
//  const getAuthenticatedUser = async () => {
//    const {
//      username,
//      signInDetails
//    } = await getCurrentUser();

//    const {
//      tokens: session
//    } = await fetchAuthSession();

    // Note that session will no longer contain refreshToken and clockDrift
//    return {
//      username,
//      session,
//      authenticationFlowType: signInDetails.authFlowType
//    };
//  }

  // Use effect for auth
//  useEffect(() => {
//    Hub.listen("auth", ({ payload: { event, data } }) => {
//      switch (event) {
//        case "signIn":
//          console.log(event);
//          console.log(data);
//          getCurrentUser().then((userData) => setUser(userData));
//          console.log(user);
//          break;
//        case "signOut":
//          setUser(null);
//          break;
//        case "signIn_failure":
//          console.log("Sign in failure", data);
//          break;
//      }
//    });
//    getUser().then((userData) => setUser(userData));
//  }, []);
  return (
    <View className='App'>
      <Card>
        <Button onClick={() => signInWithRedirect({provider: {custom: 'amebusappFederate'}})}>Login</Button>
      </Card>
    </View>
  );
}

export default App;
