import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import * as App from './App';
import * as reportWebVitals from './reportWebVitals';
import * as Amplify from 'aws-amplify';
import config from './aws-exports';

const { reAmplify } = Amplify;
reAmplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

///////////////////////////////////////////////////////////////////////////////
// index.js
///////////////////////////////////////////////////////////////////////////////
//import * as React from 'react';
//import * as ReactDOM from 'react-dom/client';
//import * as App from './App';
//import { AuthProvider } from "react-oidc-context";

//const cognitoAuthConfig = {
//  authority: "https://cognito-idp.us-west-2.amazonaws.com/us-west-2_bPXq1dfld",
//  client_id: "6pstjk2k7t6qocbnhff5ip1f4p",
//  redirect_uri: "http://localhost:3000/",
//  response_type: "code",
//  scope: "aws.cognito.signin.user.admin email openid phone profile",
//};

//const root = ReactDOM.createRoot(document.getElementById("root"));

///////////////////////////////////////////////////////////////////////////////
// wrap the application with AuthProvider
///////////////////////////////////////////////////////////////////////////////
//root.render(
//  <React.StrictMode>
//    <AuthProvider {...cognitoAuthConfig}>
//      <App />
//    </AuthProvider>
//  </React.StrictMode>
//);
